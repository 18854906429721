@import "../../scss/variables";

.agreement {
    width: 100%;
    padding: 2rem;
    border-bottom: 2px solid $color-grey-1;

    &__title {
        font-size: 1.6rem;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-primary;
    }

    &__description {
        font-size: 1.4rem;
    }

    &__check {
        display: flex;
        padding: 1rem 0 0;

        &-wrapper {
            display: flex;
            align-items: center;
            position: relative;
        }

        &-box {
            width: 3rem;
            height: 3rem;
            padding: 0;
            margin: 0;
            opacity: 0;
        }

        &-square {
            @include ff-icon;
            display: flex;
            position: absolute;
            width: 3rem;
            height: 3rem;
            border: 2px solid $color-primary;
            align-items: center;
            justify-content: center;
            background-color: $color-bg-elem;
            color: $color-bg-elem;
            transition: 0.3s ease-in-out;
            border-radius: 0.5rem;
            cursor: pointer;

            &.checked {
                background-color: $color-primary;
            }
        }

        &-label {
            font-size: 1.6rem;
            font-weight: bold;
            color: $color-primary;
            padding: 1rem 1rem;
            cursor: pointer;
        }
    }
}
