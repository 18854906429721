.form__checkbox {
    &-input {
        height: 4rem;
        width: 4rem;
        opacity: 0;
        cursor: pointer;
    }

    &-square {
        height: 3rem;
        width: 3rem;
        position: absolute;
        border: 2px solid $color-grey-5;
        border-radius: 0.5rem;
        @include ff-icon;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-label {
        font-size: 1.4rem;
        font-weight: bold;
        color: $color-primary;
        text-transform: uppercase;
        cursor: pointer;
    }
}
