.form__text-area {
    width: 100%;
    padding: 1rem 2rem;
    font-size: 1.6rem;
    border: 2px solid $color-grey-5;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    height: 10rem;
    min-height: 10rem;
    resize: vertical;
    @include ff-text;

    &:focus {
        border-color: $color-primary;
        outline: none;
    }
}
