.page-form {
    width: 100%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .form__wrapper {
        width: 100%;
        padding: 1rem 0;
        
        .form__label {
            text-align: left;
        }

        .form__field {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .form__value {
            width: 100%;
            font-size: 1.6rem;
            padding: 1rem 0;
            margin: 0.5rem 0;
        } 
    }

    &__column {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .size-1-2 {
            flex: 1 0 30%;
            padding-right: 1rem;
            padding-bottom: 1rem;
        }
        
        .size-1-6 {
            flex: 1 0 10%;
            padding-right: 1rem;
            padding-bottom: 1rem;
        }
        .size-1-3 {
            flex: 1 0 20%;
            padding-right: 1rem;
            padding-bottom: 1rem;
        }
    }
}
