.page-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 2rem;

    &__label {
        font-size: 1.2rem;
        text-transform: uppercase;
        text-align: left;
        color: $color-grey-5;
        padding-bottom: 0.5rem;
        font-weight: bold;
    }

    &__value {
        font-size: 1.6rem;
        color: $color-black;
        display: flex;
        align-items: center;

        &-icon {
            padding-left: 1rem;
            @include ff-icon;
            text-decoration: none;
            color: $color-primary;
            font-size: 1.6rem;
        }

        &.content-right {
            justify-content: flex-end;
        }

        &-b {
            color: $color-primary;
            padding-left: 0.5rem;
        }

        &.big {
            font-size: 2rem;
        }
    }

    
}
