.table {
    width: 100%;
    border-collapse: collapse;
    padding: 1rem 2rem;

    

    td,
    th {
        padding: 0;
        text-align: center;

        &.clickable {
            cursor: pointer;
        }

        &.w-min {
            width: 1px;
        }

        @for $i from 1 through 12 {
            &.w-#{$i} {
                width: ($i / 12) * 100%;
            }
        }

        &:first-child {
            padding-left: 2rem;
        }

        &:last-child {
            padding-right: 2rem;
        }

        &.t-center {
            text-align: center;
        }

        &.t-right {
            text-align: right;
        }

        &.t-left {
            text-align: left;
        }

        .button {
            display: inline-block;
        }
    }

    tbody {
        tr {
            &.clickable {
                cursor: pointer;
            }

            &:nth-child(2n) {
                background-color: $color-grey-1;
            }

            &:hover {
                background-color: $color-grey-2;
            }

            td {
                padding: 1rem 0;
            }
        }

        &.clickable {
            cursor: pointer;
        }
    }

    th {
        background-color: $color-primary;
        padding: 1rem 0;
        color: white;
    }
}
