.page-links {
    
        background-color: $color-bg-elem;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        text-transform: uppercase;
        font-size: 1.6rem;
        border-bottom: 1px solid $color-grey-2;
        padding: 0 2rem;
    
        &__link {
            text-align: center;
            padding: 0.5rem 1rem;
            color: $color-grey-5;
            text-decoration: none;
            font-size: 1.2rem;
            font-weight: bold;
            display: flex;
            align-items: center;
            border-color: transparent;
            border-width: 1px;
            border-style: solid;
            border-bottom-width: 0;
            border-radius: 0.5rem 0.5rem 0 0;

            &:hover {
                background-color: $color-grey-1;
            }

            &.active {
                border-color: $color-grey-2;
                color: $color-grey-7;
                background-color: $color-grey-1;
            }
        }
    
}