.button {
    font-size: 1.6rem;
    padding: 0.7em 1.5em;
    border: 2px solid $color-primary;
    border-radius: 0.5rem;
    background-color: $color-primary-c;
    color: $color-primary;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    transition-duration: 0.2s;
    outline: none;
    cursor: pointer;

    &:hover {
        background-color: $color-primary;
        color: $color-primary-c;
        border: 2px solid $color-primary;
    }

    &.small {
        font-size: 1rem;
        border: 1px solid $color-primary;

        &:hover {
            background-color: $color-primary;
            color: $color-primary-c;
        }
    }

    &.disabled {
        border-color: $color-grey-3;
        color: $color-grey-3;
        border: 2px solid $color-grey-3;
        cursor: not-allowed;

        &:hover {
            background-color: $color-primary-c;
            color: $color-grey-3;
            border: 2px solid $color-grey-3;
        }
    }

    &.light {
        color: $color-grey-5;

        &:hover {
            background-color: $color-primary-c;
            color: $color-primary;
        }
    }

    &.smallLight {
        font-size: 1rem;
        color: $color-grey-6;
        border: 0px solid $color-primary;

        &:hover {
            background-color: $color-primary;
            color: $color-primary-c;
        }
    }

    &.clear {
        font-size: 1.4rem;
        background-color: $color-bg;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
        font-weight: bold;
        border-width: 0;
        color: $color-primary;
        display: flex;
        align-items: center;
    }

    &.icon {
        @include ff-icon;
        padding: 0 1rem;
        height: 4.2rem;
    }

    &.icon.small {
        @include ff-icon;
        width: 3rem;
        text-align: center;
        padding: 0;
        height: 3rem;
        font-size: 2rem;
    }

    &__icon {
        @include ff-icon;
        font-size: 2rem;
    }

    &.border-top {
        width: 100%;
        border: 0px solid $color-primary;
        border-top: 1px solid $color-primary;
        padding: 2rem 0;
    }

    &.border-none {
        width: 100%;
        border: 0px solid $color-primary;
        padding: 2rem 0;
    }

    &.no-left-padding {
        padding-left: 0;
    }

    &.margin-bottom-10 {
        margin-bottom: 1rem;
    }

    &.margin-top-32 {
        margin-top: 3.2rem;
    }
    &.align-self-end {
        align-self: flex-end;
    }
    &.margin-top-10 {
        margin-top: 1rem;
    }

    &.margin-right-20 {
        margin-right: 2rem;
    }
}
