$color-bg: rgb(238, 238, 238);
$color-bg-elem: #fff;
$color-primary: rgb(2, 148, 79);
$color-primary-c: #fff;

$color-grey-1: rgb(238, 238, 238);
$color-grey-2: rgb(224, 224, 224);
$color-grey-3: rgb(200, 199, 199);
$color-grey-4: rgb(187, 187, 187);
$color-grey-5: rgb(163, 163, 163);
$color-grey-6: rgb(141, 141, 141);
$color-grey-7: rgb(122, 122, 122);
$color-grey-7: rgb(71, 71, 71);

$color-black: rgb(0, 0, 0);


$color-red: rgb(211, 36, 36);
