.form__select {
    width: 100%;
    padding: 1rem 2rem;
    font-size: 1.6rem;
    border: 2px solid $color-grey-5;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: $color-primary-c;
    cursor: pointer;

    &-arrow {
        @include ff-icon;
        position: absolute;
        right: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 4rem;
        user-select: none;
        pointer-events: none;
    }

    &:focus {
        border-color: $color-primary;
        outline: none;
    }
}
