.page__column {
    &-view {
        display: flex;
        width: 100%;
        flex-direction: column;

        @media(min-width: $dim-breakpoint-sm) {
            display: flex;
            flex-direction: row;

        }
    }

    width: 100%; 
}