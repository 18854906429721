.form {
    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__field {
        position: relative;
    }

    &__label {
        font-size: 1.4rem;
        text-transform: uppercase;
        text-align: center;
        color: $color-primary;
        font-weight: bold;
    }

    &__errors {
        color: $color-red;
        text-align: center;
        font-size: 1.2rem;
        text-transform: uppercase;
    }
}
