@import "../../scss/variables";

.order-add__product-list-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 1rem;
}

.order-add__product {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid $color-grey-3;
    border-radius: 0.5rem;
    padding: 2rem 0 0;

    &.disabled {
        opacity: 0.5;
    }

    &__title {
        padding: 0 2rem;
        font-size: 1.6rem;
        text-transform: uppercase;
        color: $color-primary;
        font-weight: bold;
    }

    &__description {
        font-size: 1rem;
        padding: 1rem;
    }

    &__value {
        font-size: 1.2rem;
        padding-bottom: 0.5rem;
    }

    &__price {
        font-size: 1.6rem;
        color: $color-primary;
    }

    &__sizes {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0;
        border-top: 2px solid $color-grey-3;
        margin-top: 2rem;
    }

    &__size {
        display: flex;
        justify-content: space-between;
        padding: 1rem 2rem;

        &:not(:last-child) {
            border-bottom: 1px solid $color-grey-3;
        }

        &-info {
            display: flex;
            flex-direction: column;

            &-name {
                font-size: 1.4rem;
                font-weight: bold;
                color: $color-primary;
            }
        }
    }

    &__quantity {
        border: 2px solid $color-primary;
        border-radius: 0.5rem;
        display: flex;
        overflow: hidden;
        align-items: center;

        &-value {
            font-size: 1.6rem;
            padding: 0 1rem;
            color: $color-primary;
            font-weight: bold;
        }

        .button {
            border: none;

            &.disabled {
                color: $color-grey-3;

                &:hover {
                    border: none;
                }
            }

            &:first-child {
                border-right: 2px solid $color-primary;
                border-radius: 0;

                &:hover {
                    border-right: 2px solid $color-primary;
                }
            }

            &:last-child {
                border-left: 2px solid $color-primary;
                border-radius: 0;

                &:hover {
                    border-left: 2px solid $color-primary;
                }
            }
        }
    }

    &-wrapper {
        flex: 0 0 100%;
        padding: 1rem;

        @media (min-width: $dim-breakpoint-sm) {
            flex: 0 0 33.3%;
        }
    }
}

.order-add__footer {
    width: 100%;
    position: fixed;
    background-color: $color-bg-elem;
    bottom: 0rem;
    display: flex;
    padding: 2.5rem 2rem;
    font-size: 1.7rem;
    justify-content: center;
    box-shadow: 0 0 5px 1px $color-grey-3;
    z-index: 3;

    &-wrapper {
        width: $dim-page-max-width;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.order-add__footer__balance b {
    color: $color-primary;
}

.order-add__table {
    width: 100%;
    border-collapse: collapse;
    padding: 1rem 2rem;

    td,
    th {
        font-size: 1.2rem;
        padding: 0;
        text-align: center;

        &.w-min {
            width: 1px;
        }

        @for $i from 1 through 12 {
            &.w-#{$i} {
                width: ($i/12) * 100%;
            }
        }
        &:first-child {
            padding-left: 2rem;
        }

        &:last-child {
            padding-right: 2rem;
        }

        &.t-center {
            text-align: center;
        }

        &.t-right {
            text-align: right;
        }

        &.t-left {
            text-align: left;
        }
    }

    tbody {
        tr {
            &:nth-child(2n) {
                background-color: $color-grey-1;
            }

            td {
                padding: 2rem 1rem;
            }
        }
    }

    th {
        background-color: $color-primary;
        padding: 1rem 0;
        color: white;
        font-size: 1.2rem;
    }
}
